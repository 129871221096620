// extracted by mini-css-extract-plugin
export var bg1 = "pricingCard-module--bg1--9fa6c";
export var bg2 = "pricingCard-module--bg2--72d13";
export var bodyContainer = "pricingCard-module--bodyContainer--59c66";
export var cardContainer = "pricingCard-module--cardContainer--63616";
export var close = "pricingCard-module--close--81c82";
export var coloredArea = "pricingCard-module--coloredArea--37a50";
export var headerContainer = "pricingCard-module--headerContainer--b5200";
export var iconWrapper = "pricingCard-module--iconWrapper--7c6f2";
export var modalBody = "pricingCard-module--modalBody--ec0c5";
export var modalContent = "pricingCard-module--modalContent--2925b";
export var modalHeader = "pricingCard-module--modalHeader--b2f17";
export var optionBtn = "pricingCard-module--optionBtn--8beb0";
export var questionContainer = "pricingCard-module--questionContainer--ef805";
export var successPopUpHeading = "pricingCard-module--successPopUpHeading--f5bf1";
export var successPopUpThanksHeading = "pricingCard-module--successPopUpThanksHeading--b15a8";
export var tickText = "pricingCard-module--tickText--3e912";