import React from "react"
import Spinner from "react-bootstrap/Spinner"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import Pricing from "../components/pricing-sections/pricing"
import MainLayout from "../layouts/MainLayout"

const Insights = React.lazy(() =>
  import("../components/pricing-sections/insights/insights")
)
const Testimonials = React.lazy(() =>
  import("../components/common/testimonials/Testimonials")
)
const PricingPage = () => {
  const isSSR = typeof window === "undefined"

  return (
    <MainLayout bgChanged={false}>
      <Pricing />
      {!isSSR && (
        <React.Suspense
          fallback={
            <section>
              <div className="text-center">
                <Spinner
                  variant="primary"
                  size="sm"
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            </section>
          }
        >
          <Testimonials />
        </React.Suspense>
      )}
      {!isSSR && (
        <React.Suspense
          fallback={
            <section>
              <div className="text-center">
                <Spinner
                  variant="primary"
                  size="sm"
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            </section>
          }
        >
          <Insights />
        </React.Suspense>
      )}
      <ContactSales />
    </MainLayout>
  )
}

export default PricingPage

export const Head = () => {
  return (
    <SEORevamp
      title="Pricing | InvoZone Software Development"
      description="Choose from InvoZone’s 3 pricing models i.e. Staff Augmentation, Dedicated Development Team, Fixed Gigs; as per your business need."
      image="https://invozone-backend.s3.amazonaws.com/Business_Models_4bf18a26b4.png"
    />
  )
}
