import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import svgWebDev from "../../../images/icons/web-design-dev.svg"
import svgCompleted from "../../../images/illustrations/completed.svg"
import dedicated_team_tick from "../../../images/pricing-page/dedicated_team_tick.svg"
import prod_dev_tick from "../../../images/pricing-page/prod_dev_tick.svg"
import resource_aug_tick from "../../../images/pricing-page/resource_aug_tick.svg"
import * as styles from "./pricingCard.module.scss"
const PrizingForm = React.lazy(() => import("../../common/PrizingForm"))

const PricingCard = ({ data, index = 0, btnId = "" }) => {
  const [quest, setQuest] = useState({})
  const [errors, setErrors] = useState({})
  const [show, setShow] = useState(false)
  const [formStatus, setFormStatus] = useState("init")
  const isSSR = typeof window === "undefined"
  const [inputValue, setInputValue] = useState("")
  const [selectedValues, setSelectedValues] = useState([])

  const handleChange = (value, e) => {
    if (errors.hasOwnProperty(e.name)) {
      delete errors[e.name]
      setErrors(errors)
    }
    setQuest({ ...quest, [e.name]: value })
  }

  const components = {
    DropdownIndicator: null,
  }
  const handleChangeTag = (newValue, actionMeta) => {
    setSelectedValues(newValue)
  }

  const handleInputChange = input => {
    setInputValue(input)
  }

  const handleKeyDown = event => {
    if (!inputValue) return
    switch (event.key) {
      case "Enter":
      case "Tab":
        const trimmedInput = inputValue.trim()
        if (
          trimmedInput &&
          !selectedValues.some(option => option.label === trimmedInput)
        ) {
          setSelectedValues([
            ...selectedValues,
            { value: trimmedInput, label: trimmedInput },
          ])
        }
        setInputValue("")
        event.preventDefault()
        break
      default:
        break
    }
  }

  const colourStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      width: "100%",
      minHeight: "43px",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#000000",
      backgroundColor: "#fff",
      // border: "0px !important",
      borderRadius: "0.75rem",
      boxShadow: isFocused && "0 0 0 0.2rem rgb(33 118 255 / 25%)",
    }),
    // option: (styles, { isFocused, isSelected }) => {
    //   return {
    //     ...styles,
    //     backgroundColor: isSelected ? "#1CA0EA" : "#fff",
    //     "&:hover": {
    //       backgroundColor: isFocused && "#2179FE",
    //       color: "#fff",
    //     },
    //   }
    // },
    menu: styles => ({
      ...styles,
      backgroundColor: "#fff",
      color: "#000",
      zIndex: 5,
    }),
    dropdownIndicator: styles => ({
      ...styles,
      color: "#000", // Custom colour
    }),
  }

  const fallback = (
    <div className="text-center">
      <Spinner
        variant="primary"
        size="sm"
        as="span"
        animation="border"
        role="status"
        aria-hidden="true"
      />
    </div>
  )

  const handleClose = () => {
    setShow(false)
    setFormStatus("init")
  }

  const validate = () => {
    let newErrors = {}

    if (!quest[data?.questions?.question1?.value]?.value) {
      newErrors[data?.questions?.question1?.value] = "This field is required!"
    }
    if (!quest[data?.questions?.question2?.value]?.value) {
      newErrors[data?.questions?.question2?.value] = "This field is required!"
    }
    return newErrors
  }

  const handleShow = async () => {
    const validationErrors = await validate()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    setShow(true)
  }

  const techOptions = [
    { value: "ReactJs", label: "ReactJs" },
    { value: "NodeJs", label: "NodeJs" },
    { value: "Elixir", label: "Elixir" },
    { value: "Flutter", label: "Flutter" },
    { value: "Python", label: "Python" },
    { value: "JavaScript", label: "JavaScript" },
    { value: "Golang", label: "Golang" },
    { value: "RoR", label: "RoR" },
    { value: "Django", label: "Django" },
    { value: "Backend", label: "Backend" },
    { value: "Frontend", label: "Frontend" },
    { value: "MERN stack", label: "MERN stack" },
    { value: "MEAN stack", label: "MEAN stack" },
    { value: "UI/UX", label: "UI/UX" },
  ]

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
        contentClassName={styles.modalContent}
      >
        <Modal.Header className={styles.modalHeader} closeButton></Modal.Header>
        <Modal.Body className={styles.modalBody}>
          {formStatus === "success" ? (
            <div className="mt-4">
              <img
                decoding="async"
                loading="lazy"
                src={svgCompleted}
                alt="Submitted"
                width={300}
              />
              <div className={`mt-4 mb-2 ${styles.successPopUpHeading}`}>
                We have noted your requirements successfully!
              </div>
              <div className={`px-5 ${styles.successPopUpThanksHeading}`}>
                Thank you for taking time, <br /> we will get back to you
                shortly!
              </div>
            </div>
          ) : (
            <React.Fragment>
              {formStatus !== "success" && (
                <div>
                  <span className={styles.iconWrapper}>
                    <img
                      decoding="async"
                      loading="lazy"
                      src={svgWebDev}
                      alt="web dev"
                      width={48}
                      height={48}
                    />
                  </span>
                </div>
              )}
              {formStatus !== "success" && (
                <div className="h4 mt-4 mb-2 servicename">{data?.heading}</div>
              )}
              <div className="mt-2 mb-4 px-5" style={{ fontSize: "28px" }}>
                {
                  "Fill in the details below and we'll zone in your project in no time!"
                }
              </div>
              {!isSSR && (
                <React.Suspense fallback={fallback}>
                  <PrizingForm
                    status={formStatus}
                    setStatus={setFormStatus}
                    title={data?.heading}
                    question1={data?.questions?.question1?.label}
                    answer1={quest[data?.questions?.question1?.value]?.value}
                    question2={data?.questions?.question2?.label}
                    answer2={quest[data?.questions?.question2?.value]?.value}
                    question3={data?.questions?.question3?.label}
                    answer3={selectedValues.map(item => item.value).join(", ")}
                    // optionValues={[
                    //   `serviceType? : ${data?.heading}`,
                    //   ` ${data?.questions?.question1?.label} : ${
                    //     quest[data?.questions?.question1?.value]?.value
                    //   }`,
                    //   `${data?.questions?.question2?.label} : ${
                    //     quest[data?.questions?.question2?.value]?.value
                    //   }`,
                    // ]}
                  />
                </React.Suspense>
              )}
            </React.Fragment>
          )}
        </Modal.Body>
      </Modal>

      <div className={styles.cardContainer}>
        <div className={`${styles.headerContainer} ${styles[`bg${index}`]}`}>
          <div>
            {data?.icon}
            <h5 className="pt-2">{data?.heading}</h5>
            <p>{data?.subHeading}</p>
          </div>
        </div>
        <div className={styles.bodyContainer}>
          <h5 className="pb-3">{data?.heading}</h5>
          {data?.servicesList?.map((item, ind) => (
            <Row key={ind}>
              <Col xs={2} sm={1} md={2} lg={1} xl={1}>
                <img
                  src={
                    index === 1
                      ? prod_dev_tick
                      : index === 2
                      ? dedicated_team_tick
                      : resource_aug_tick
                  }
                  alt={item}
                  decoding="async"
                  loading="lazy"
                  height={18}
                />
              </Col>
              <Col
                xs={10}
                sm={11}
                md={10}
                xl={11}
                key={ind}
                className={styles.tickText}
              >
                <p>{item}</p>
              </Col>
            </Row>
          ))}
        </div>
        <div className={styles.questionContainer}>
          <Row>
            <Col xs={12}>
              <p className="font-weight-bold pb-2 mb-0">
                {data?.questions?.question1?.label}{" "}
                <span className="text-danger">*</span>
              </p>
            </Col>
            <Col xs={12}>
              <Select
                name={data?.questions?.question1?.value}
                value={quest[data?.questions?.question1?.value] || ""}
                onChange={handleChange}
                options={data?.questions?.options1}
                styles={colourStyles}
                // placeholder={place[data?.questions?.question1?.value] || ''}
                components={{
                  IndicatorSeparator: () => null,
                }}
                indicatorSeparator={false}
              />
              {errors[data?.questions?.question1?.value] && (
                <small type="invalid" className="text-danger">
                  {errors[data?.questions?.question1?.value]}
                </small>
              )}
            </Col>
            <Col xs={12}>
              <p className="font-weight-bold pt-3 pb-2 mb-0">
                {data?.questions?.question2?.label}{" "}
                <span className="text-danger">*</span>
              </p>
            </Col>
            <Col xs={12}>
              <Select
                name={data?.questions?.question2?.value}
                value={quest[data?.questions?.question2?.value] || ""}
                onChange={handleChange}
                options={data?.questions?.options2}
                // placeholder={place[data?.questions?.question2?.value]}
                styles={colourStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                indicatorSeparator={false}
              />
              {errors[data?.questions?.question2?.value] && (
                <small type="invalid" className="text-danger">
                  {errors[data?.questions?.question2?.value]}
                </small>
              )}
            </Col>
            <Col xs={12}>
              <p className="font-weight-bold pt-3 pb-2 mb-0">
                {data?.questions?.question3?.label}
              </p>
            </Col>
            <Col xs={12}>
              <CreatableSelect
                components={components}
                isClearable
                isMulti
                value={selectedValues}
                onChange={handleChangeTag}
                onInputChange={handleInputChange}
                inputValue={inputValue}
                onKeyDown={handleKeyDown}
                placeholder="Enter your preferred tech stack..."
                // menuIsOpen={false}
                options={techOptions}
                styles={colourStyles}
              />
            </Col>
            <Col xs={12} className=" pt-4 text-center">
              <button
                onClick={handleShow}
                className="btn_black_border"
                id={btnId}
              >
                Get A Free Quote
              </button>
            </Col>
          </Row>
        </div>
        <div className={`${styles.coloredArea} ${styles[`bg${index}`]}`} />
      </div>
    </React.Fragment>
  )
}

export default PricingCard
